<template>
	<div class="home flex-center flex-column">
		<v-container class="vuetify-container flex-center flex-column px-10 px-md-0">
			<div class="w-100 flex-center mb-12">
				<svg class="mta-logo" width="66" height="40" viewBox="0 0 66 40" fill="none" xmlns="http://www.w3.org/2000/svg" style="cursor:pointer;" @click="goToWebsite('https://mtabraham.com')">
					<path d="M51.4223 13.8303H50.886L47.322 26.4992H54.9863L51.4223 13.8303ZM45.0533 34.5799L43.5023 39.9918H58.7978L57.2468 34.5799H45.0533ZM8.3655 13.3278H8.91825L16.9373 0H0.60225L8.3655 13.3278ZM65.9258 34.0939V5.74959H56.2155L65.9258 34.0939ZM40.326 18.4926L42.5205 11.0049H34.6913V5.74959H25.575V28.6573C25.575 31.4662 26.4825 33.4926 28.116 34.8517H40.6148L39.4928 30.5272C39.006 30.5272 38.0573 30.5107 37.257 30.5107C35.541 30.5107 34.683 29.7199 34.683 28.1219V18.4926H40.326ZM15.609 20.3295L8.48925 33.0642H7.89525L0.858 20.3295H0.429L0 34.8517H16.467L16.038 20.3295H15.609Z" fill="#FDFDFE"></path>
				</svg>
				<v-divider vertical color="#D92D8A" class="mx-12"></v-divider>
				<svg width="66" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 35.36" class="mta-logo" @click="goToWebsite('https://vecernji.ba')">
					<g id="Layer_2" data-name="Layer 2">
						<g id="Layer_1-2" data-name="Layer 1">
							<polygon class="cls-1" points="11.28 35.08 0 0 11.07 0 17.36 25.02 24.16 0 34.43 0 22.85 35.36 11.64 35.36 11.28 35.08"/>
							<polygon class="cls-1" points="58 35.36 36.74 35.36 36.74 0 45.71 0 45.71 26.93 58 26.93 58 35.36"/>
						</g>
					</g>
				</svg>
			</div>
			<img src="../assets/logo.svg" alt="EQUALITY FORUM" class="home-logo my-12">
			<v-row class="w-100 ma-0 pa-0 mt-12">
				<v-col cols="6" class="flex-center option-1 white--text option" @click="$router.push('/2021')">
					<span class="w-100 text-center white--text">2021</span>
					<div class="option-overlay w-100"></div>
				</v-col>
				<v-col cols="6" class="flex-center option-2 white--text option" @click="$router.push('/2022')">
					<span class="w-100 text-center white--text">2022</span>
					<div class="option-overlay w-100"></div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
export default {
	name: "Home",
	methods: {
		goToWebsite(site) {
			window.open(site, '_blank').focus()
		}
	}
}
</script>

<style lang="scss" scoped>
.home-logo {
	width: 400px;
	height: auto;
	max-width: 100%;
}
.home {
	min-height: 100vh;
}
.cls-1{fill:#fff;}
.mta-logo {
	cursor: pointer;
	&:hover {
		path, .cls-1 {
			fill: #D92D8A;
		}
	}
	path, .cls-1 {
		transition: .5s ease;
	}
}
.option-1 {
	cursor: pointer;
	border-right: 1px solid #D92D8A90;
	.option-overlay {
		right: 0;
		opacity: 0;
		background: linear-gradient(to left, #D92D8A, #D92D8A00);
	}
}
.option-2 {
	cursor: pointer;
	.option-overlay {
		right: 1;
		opacity: 0;
		background: linear-gradient(to right, #D92D8A, #D92D8A00);
	}
}
.option {
	position: relative;
	.option-overlay {
		position: absolute;
		min-height: 200px;
		bottom: 0;
		transition: .5s ease;
	}
	span {
		font-size: 48px;
		z-index: 2;
	}
	min-height: 200px;
	&:hover {
		.option-overlay {
			opacity: 1;
			transition: .5s ease;
		}
	}
}
@media(max-width: 950px) {
	.option {
		margin-bottom: 70px;
		span {
			font-size: 25px;
		}
		min-height: 150px;
		.option-overlay {
			min-height: 150px !important;
		}
	}
}
</style>