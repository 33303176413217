import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import vuetify from './plugins/vuetify'
var VueScrollTo = require('vue-scrollto');
 
Vue.use(VueScrollTo)
 
Vue.use(VueScrollTo, {
     container: "body",
     duration: 500,
     easing: "ease",
     offset: 200,
     force: true,
     cancelable: true,
     x: false,
     y: true
 })

Vue.config.productionTip = false

new Vue({
	vuetify,
	router,
	render: h => h(App)
}).$mount('#app')
