import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import TwentyOne from "../views/TwentyOne.vue"
import TwentyTwo from "../views/TwentyTwo.vue"

Vue.use(VueRouter);

const routes = [
    {
		path: "/",
		name: "Home",
		component: Home,
	},
    {
		path: "/2021",
		name: "TwentyOne",
		meta: {
			year: 2021
		},
		component: TwentyOne,
	},
	{
		path: "/2022",
		name: "TwentyTwo",
		meta: {
			year: 2022
		},
		component: TwentyTwo,
	},
	{
		path: "/404",
		name: "NotFound",
		component: () => import('../views/NotFound.vue')
	},
	{
		path: '*',
		redirect: {
			name: 'NotFound',
			component: () => import('../views/NotFound.vue')
		}
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	linkExactActiveClass: "route-active"
})

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0);
	return next();
})

export default router;
