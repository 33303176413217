<template>
	<v-app>
		<v-app-bar app color="#151a21" fixed dark flat v-if="!['Home', 'NotFound'].includes($route.name) && navHeight" :height="navHeight" class="px-2" style="border-bottom: 1px solid #FFFFFF20">
			<v-container class="w-100 d-flex align-start justify-center vuetify-container">
				<img @click="$router.push('/')" src="./assets/logo-2021-2.svg" alt="Equality 2021" class="nav-logo" v-if="$route.name == 'TwentyOne'">
				<img @click="$router.push('/')" src="./assets/logo.svg" alt="Equality 2022" class="nav-logo" v-if="$route.name == 'TwentyTwo'">
	
				<v-spacer></v-spacer>
	
				<div v-for="(item,x) in nav" :key="x" class="hidden-md-and-down">
					<v-btn tile :href="item.link" target="_blank" plain v-if="item.link && item.years.includes($route.meta.year)">
						{{item.name}}
					</v-btn>
					<v-btn tile v-scroll-to="{ el: item.target, offset: -170 }" plain v-else-if="item.target && item.years.includes($route.meta.year)">
						{{item.name}}
					</v-btn>
				</div>
	
				<v-app-bar-nav-icon dark @click.stop="drawer = !drawer" class="hidden-lg-and-up"></v-app-bar-nav-icon>
			</v-container>
		</v-app-bar>

		<v-navigation-drawer fixed dark temporary v-model="drawer" style="z-index: 10" color="#151a21" v-if="!['Home', 'NotFound'].includes($route.name)">
			<div class="w-100 flex-center pa-5">
				<img @click="$router.push('/')" src="./assets/logo2021.svg" alt="Equality 2021" class="w-100" v-if="$route.name == 'TwentyOne'">
				<img @click="$router.push('/')" src="./assets/logo2022.svg" alt="Equality 2022" class="w-100" v-if="$route.name == 'TwentyTwo'">
			</div>
			
			<v-list nav class="w-100">
				<v-list-item-group>
					<div class="w-100" v-for="(item,y) in nav" :key="y">
						<v-list-item v-if="item.target && item.years.includes($route.meta.year)">
							<v-btn tile v-scroll-to="{ el: item.target, offset: -100 }" plain v-if="item.target" class="w-100" @click="drawer = !drawer">
								{{item.name}}
							</v-btn>
						</v-list-item>
						<v-list-item v-else-if="item.link && item.years.includes($route.meta.year)">
							<v-btn :href="item.link" target="_blank" tile plain class="w-100" @click="drawer = !drawer">
								{{item.name}}
							</v-btn>
						</v-list-item>
					</div>
					
				</v-list-item-group>

			</v-list>
		</v-navigation-drawer>
	
		<v-main class="main">
			<router-view></router-view>
		</v-main>

		<div class="w-100 footer flex-center pt-4 pb-2" style="background-color: #151a21; border-top: 1px solid #FFFFFF20;">
			<span class="w-100 text-center white--text font-weight-light overline">
				&copy; Copyright Equality Forum, 2021 - 2022
			</span>
		</div>
	</v-app>
</template>

<script>
export default {
	name: 'App',
	data: () => ({
		windowWidth: window.innerWidth,
		drawer: false,
		navHeight: null,
		nav: [{
				name: 'Home',
				target: '#home',
				years: [2021, 2022]
			},
			{
				name: 'Speakers',
				target: '#speakers',
				years: [2021]
			},
			{
				name: 'Agenda',
				target: '#agenda',
				years: [2021, 2022]
			},
			{
				name: 'About',
				target: '#about',
				years: [2022]
			},
			{
				name: 'Statements',
				target: '#statements',
				years: [2021]
			},
			{
				name: 'Contact',
				target: '#contact',
				years: [2021, 2022]
			},
			{
				name: 'Gender Equality Law',
				link: 'https://arsbih.gov.ba/english/law-on-gender-equality-in-b-h/',
				years: [2021, 2022]
			}
		]
	}),
	watch: {
		windowWidth(newWidth) {
			if(newWidth < 1264) {
				this.navHeight = 80
			} else {
				this.navHeight = 150
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);

			if(this.windowWidth < 1264) {
				this.navHeight = 80
			} else {
				this.navHeight = 150
			}
		})
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		onResize() {
			this.windowWidth = window.innerWidth
		}
	}
};
</script>

<style lang="scss">
* {
	font-family: 'Montserrat', sans-serif;
}
body,
.main {
	background-color: #151a21;
}

.v-toolbar__content {
	padding-right: 0 !important;
	padding-left: 0 !important;
}
.w-100 {
	width: 100%;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.vuetify-container {
	min-width: 90%;
	max-width: 1300px !important;
}

.nav-logo {
	height: 70px;
	cursor: pointer;
}

.section-1 {
	min-height: calc(100vh - 150px);
}

.pink-text {
	color: #D92D8A !important;
}

.contact-info {
	font-size: 14px;
}

.main-title {
	font-size: 40px;
	line-height: 45px;
}

.agenda-span {
	color: rgba(255, 255, 255, .65);
}
.speaker-img {
	margin-top: 60px;
	align-self: flex-start;
	margin-bottom: 40px;
}

@media(max-width: 1264px) {
	.main-title {
		font-size: 25px;
		line-height: 30px;
	}
	.page {
		padding-top: 80px;
	}
	.nav-logo {
		height: 40px;
	}
	.contact-info {
		font-size: 15px;
	}
	.section-3, .section-4 {
		h2 {
			font-size: 15px;
		}
	}
	.agenda-indent {
		padding-left: 64px !important;
	}
}
</style>
