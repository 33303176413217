<template>
	<div class="page twenty-one px-2 pb-12" id="home">
		<v-container class="w-100 flex-center flex-column vuetify-container section-1">
			<h1 class="w-100 white--text font-weight-regular main-title">
				“Creating fruitful surroundings for community and <br class="hidden-sm-and-down">corporate awareness, celebrating gender, religious and racial diversity and fostering social change.”
			</h1>
			<!--span class="w-100 pink-text mt-3" style="margin-bottom: 150px;">
				2021 JULY 16 // 11.30 AM – 17.30 PM
			</span-->
		</v-container>
	
		<v-container class="w-100 flex-center flex-column vuetify-container section-2 mb-12" id="speakers">
			<h1 class="font-weight-light w-100 white--text mb-10">SPEAKERS</h1>
	
			<span class="w-100 font-weight-bold pink-text">His Excellency Noah Gal Gendler</span>
			<span class="w-100 white--text mb-5">Ambassador to the state of Israel in Bosnia and Herzegovina</span>
	
			<span class="w-100 font-weight-bold pink-text">Mr. Amir Gross Kabiri</span>
			<span class="w-100 white--text mb-5">Chairmain of M.T. Abraham Group</span>
	
			<span class="w-100 font-weight-bold pink-text">Dr. Mateo Čovčić</span>
			<span class="w-100 white--text mb-5">Psychologist</span>
	
			<span class="w-100 font-weight-bold pink-text">Mr. Damir Banović</span>
			<span class="w-100 white--text mb-5">Ph.D, Assistant professor, University of Sarajevo - Faculty of Law</span>
	
			<span class="w-100 font-weight-bold pink-text">Mrs. Dragana Kraljević</span>
			<span class="w-100 white--text mb-5">Head of Human Resources at Aluminij Industries</span>
	
			<span class="w-100 font-weight-bold pink-text">Mrs. Ivona Čarapina Zovko</span>
			<span class="w-100 white--text mb-5">Ph.D, Assistant professor, University of Sarajevo - Faculty of Humanities and Social Sciences</span>
	
			<span class="w-100 font-weight-bold pink-text">Mr. Marko Mihailović</span>
			<span class="w-100 white--text">Director of Pride society Serbia</span>
		</v-container>
	
		<v-container class="w-100 flex-center flex-column vuetify-container section-3 mt-12" id="agenda">
			<h1 class="font-weight-light w-100 white--text text-lg-left text-md-center text-center">AGENDA <span class="hidden-md-and-down">SCHEDULE</span></h1>
			<div class="w-100 mt-6 mb-12 d-flex align-center justify-lg-start justify-md-center justify-center">
				<v-divider style="max-width: 80px" color="#D92D8A"></v-divider>
			</div>
			<h2 class="w-100 font-weight-bold mb-5 text-uppercase">
				<span class="pink-text">11:30</span>
				<span class="white--text pl-md-1 pl-0" style="margin-left: 28px;">Reception</span>
			</h2>
	
			<!--h2 class="w-100 font-weight-bold mb-3 text-uppercase">
				<span class="pink-text">12:00</span>
				<span class="white--text pl-md-0" style="margin-left: 17px; padding-left: 3px;">
					His Excellency Noah Gal Gendler
				</span>
			</h2-->
			<div class="d-flex w-100 mb-3">
				<h2 class="pink-text">12:00</h2>
				<h2 class="white--text text-uppercase pl-md-0" style="margin-left: 22px; padding-left: 3px;">
					His Excellency Noah Gal Gendler
				</h2>
			</div>
			<span class="w-100 agenda-span mb-5 agenda-indent" style="padding-left: 87px">
				Critical thinking and its positive impact on the community.
			</span>
	
			<h2 class="w-100 font-weight-bold mb-3 text-uppercase agenda-indent" style="padding-left: 87px">
				<span class="white--text">
					Introduction by Mr. Amir Gross Kabiri
				</span>
			</h2>
			<span class="w-100 agenda-span mb-5 agenda-indent" style="padding-left: 87px">Visible leadership: Importance of Allies and role models + preparing an annual program and agenda to pursue the forums conclusion</span>
	
			<h2 class="w-100 font-weight-bold mb-3 text-uppercase">
				<span class="pink-text">12:15</span>
				<span class="white--text" style="margin-left: 26px">
					First Session
				</span>
			</h2>
			<span class="w-100 agenda-span mb-3 agenda-indent" style="padding-left: 87px">The position of LGBTQIA + people in Bosnia and Herzegovina</span>
			<span class="w-100 agenda-span mb-3 agenda-indent" style="padding-left: 87px">Recognizing the difficulties faced by LGBTQIA + people in education and the labor market (Mateo Čovčić, Psychologist)</span>
			<span class="w-100 agenda-span mb-3 agenda-indent" style="padding-left: 87px">Legislation as the foundation of change</span>
			<span class="w-100 agenda-span mb-3 agenda-indent" style="padding-left: 87px">Analysis of the human rights situation in Bosnia and Herzegovina (Damir Banović Ph.D, Assistant professor, University of Sarajevo - Faculty of Law)</span>
			<span class="w-100 agenda-span mb-5 agenda-indent" style="padding-left: 87px">Strengthening the position of LGBTQIA+ community in society through corporate policies (Dragana Kraljević, Head of Human Resources at Aluminij Industries)</span>
	
			<h2 class="w-100 font-weight-bold mb-5 text-uppercase">
				<span class="pink-text">14:30</span>
				<span class="white--text" style="margin-left: 24px">Soft Lunch Break</span>
			</h2>
	
			<h2 class="w-100 font-weight-bold mb-3 text-uppercase">
				<span class="pink-text">15:00</span>
				<span class="white--text" style="margin-left: 20px">
					Second Session
				</span>
			</h2>
			<span class="w-100 agenda-span mb-3 agenda-indent" style="padding-left: 87px">Diversity in the workplace: Examining benefits of diverse working environment on organizational success (Ivona Čarapina Zovko Ph.D, Assistant professor, University of Mostar - Faculty of Humanities and Social Sciences)</span>
			<span class="w-100 agenda-span mb-5 agenda-indent" style="padding-left: 87px">Analysis of inclusive business models: recognizing weaknesses and opportunities for development of LGBTQIA+ tourism (Marko Mihailović, Director of Pride society Serbia)</span>
	
			<h2 class="w-100 font-weight-bold mb-5 text-uppercase">
				<span class="pink-text">17:00</span>
				<span class="white--text" style="margin-left: 24px">Path into the future</span>
			</h2>

			<div class="d-flex w-100 mb-3">
				<h2 class="pink-text">17:30</h2>
				<h2 class="white--text text-uppercase pl-md-1" style="margin-left: 22px; padding-left: 3px;">
					Closing the 1st Equality Forum
				</h2>
			</div>
		</v-container>
	
		<v-container class="w-100 flex-center flex-column vuetify-container section-2 mb-12 mt-12" id="statements">
			<h1 class="font-weight-light w-100 white--text mb-12">STATEMENTS</h1>
	
			<img src="../assets/noah.png" alt="Noah Gal Gendler" style="align-self: flex-start; margin-bottom: 40px; margin-top: 10px;">
			<span class="w-100 font-weight-bold pink-text">Statement by His Excellency Noah Gal Gendler</span>
			<span class="w-100 white--text mb-3">Ambassador to the state of Israel in Bosnia and Herzegovina</span>
			<span class="w-100 agenda-span mb-5">
				Throughout history, the social institutions responsible for controlling what is considered moral and normal in relation to sexual behavior have changed, and their determinations have affected the social status of lgbt's (lesbian, gay, bisexual, and transgender). Today the social trend tends to accept lgbt's in many countries.
				<br><br>It is important to remember that any discussion of sexual orientation affects the lives of masses of lgbt's people and should therefore be conducted while separating beliefs, emotions and facts. Any dialog should be based on research and humane rational thinking.
				<br><br>As Jews, who for years were a persecuted minority for no reason on our part, and in spite that our contribution as a minority was immeasurably greater than our percentage in the population, we have learned in our flesh and blood that
				the position of the liberal public opinion should be as follows:
				<br><br>Every minority and every person with a different opinion is allowed to live his life according to his view, as long as it is not harmful to others.
				<br><br>Diversity is power - the greater the diversity of people in a society, the greater its complexity and the creativity of its people.
				<br><br>Minorities, also due to their great vulnerability, are, in many cases, more sensitive people and therefore excel in life directions where high emotional intelligence is required.
				<br><br>In Israel, in recent years, Tel Aviv has become a triple capital:
				<br>The world vegan capital.
				<br>A world capital of LGBT's
				<br>And the high-tech capital of Israel
				<br><br>As I understand it, it is no coincidence that this combination has been created.
				<br><br>I wish us all to stop fearing our demons! - This will make us better human beings
				<br>God bless us all.
			</span>
	
			<img src="../assets/amir.png" alt="Amir Gross Kabiri" class="speaker-img">
			<span class="w-100 font-weight-bold pink-text">Statement by Mr. Amir Gross Kabiri</span>
			<span class="w-100 white--text mb-3">Chairmain of M.T. Abraham Group</span>
			<span class="w-100 agenda-span mb-5">
				I grew up in Tel Aviv, a diverse and multicultural city which today, is considered to be one of the most liberal cities in the world. This diversity and openness is one of the reasons Tel Aviv has become such a success story, attracting corporations, business opportunities and tourist who all feel welcomed by the city's inclusive atmosphere.
				<br><br>
				One of the values of my corporation is giving back to the community, being involved, and having a positive effect on the environment in which we operate. Therefore, I initiated this forum.
				<br><br>
				I believe the M.T. Abraham Group and me personally can give back to BIH not just from my corporate culture and business abilities, but also from my social and cultural influence.
				<br><br>
				Alongside the contribution to the economy, I wish to contribute to the community and having a positive influence that will affect the next generations.
				<br><br>
				I also believe the two go hand in hand, as major international corporations choose to operate in inclusive multicultural cities that embody the modern and progressive norms that are acceptable by the international community.
				<br><br>
				People's views on the LGBT community and its rights are a result of personal beliefs, values, experiences, religion, education and in many cases lack of knowledge and exposure to information. Many of these factors are emotional and are partially a result of the place, time, and society we live in.
				<br><br>
				Many societies and cultures throughout human history had different norms. In some, gender roles and sexual orientation were perceived differently and were much more fluid and open than they are today in many societies.
				<br><br>
				History has also had darker times, gay men and women, Jewish and non-Jewish, have been persecuted by the Nazi regime which arrested and prosecuted tens of thousands, many of them were sent to concentration camps and lost their lives between 1933-1945.
				<br><br>
				The ones that were more fortunate had to live their lives in fear, constantly hiding a secret and lying to friends, family and loved ones. But even today, almost a century later, many people still need to live their lives in constant fear. Their everyday reality is frighteningly similar to the reality during the darkest chapter in human history.
				<br><br>
				Recently there has been an alarming rise in hate crimes against the LGBT community and a large number of victims lost their lives purely because of their sexual orientation.
				<br><br>
				Being the target of discrimination, violence and hate and living in an unsafe environment takes a toll on the well-being and mental health of the LGBT community. Suicide rate among LGBT youth is dramatically higher compared to heterosexual youth.
				<br><br>
				The LGBT community is diverse and has many colors. Having to face challenges and difficulties, LGBT people are often strong, resilient, creative, and extremely compassionate individuals.
				<br><br>
				It is our responsibility, as a modern society, and for me, as the head of the largest corporation in BIH and in the Balkans, to eliminate any discrimination and to make sure that every individual life in a safe environment in which they can live their life and get the same rights regardless of their gender, religion, sexual orientation, color or political views.
				<br><br>
				There is much work to be done, but change starts in events like this and in people like you all, who are determined to educate the public and to shape a just, inclusive, and humane society while eliminating fear, ignorance, and prejudice.
				<br><br>
				Change starts with influencers and leaders and the reforms they bring to the judicial system. It is a long process but a vital one for every society that wishes to thrive.
				<br><br>
				We must always remember that the fight for LGBT rights is a flight for human rights. As CEO of this company, I will not tolerate any discrimination, and will be persistent with my goal to make Aluminij the most diverse corporation in the Balkans, a place of tolerance, freedom, and creative minds.
				<br><br>
				I would like to thank you all for attending this forum, which I intend to hold annually, consistently, until a real change will take place.
			</span>
	
			<img src="../assets/mateo.png" alt="Mateo Čovčić" class="speaker-img">
			<span class="w-100 font-weight-bold pink-text">Statement by Dr. Mateo Čovčić</span>
			<span class="w-100 white--text mb-3">Psychologist</span>
			<span class="w-100 agenda-span mb-5">
				Despite the existence of Law prohibiting discrimination against people on the basis of gender, sexual orientation and sexual characteristics in Bosnia and Herzegovina, discrimination and stigmatization of LGBT people is widely present in the public and private spheres of life. Upon entering school, LGBTI people are the target of verbal and physical abuse. Growing up, they encounter unequal treatment in all pores of social life. They do not enjoy the same socio-economic status and opportunities for social benefits that heterosexual couples have, such as the possibility of raising a loan with a partner, inheriting a partner’s property, visiting a partner in a hospital and so on.
				<br><br>
				Whatsmore, in the workplace they face threatening messages and inappropriate jokes from work colleagues, limited employment opportunities as well as promotions. Homophobia and transphobia are reflected in the silence of citizens and the passivity of the social institutions. This negatively contributes to the personal well-being of LGBTI people, which in turn negatively affects the well-being and development of society. The Equality Forum broke the silence and pointed out the difficulties in society that LGBTI people face. As stated earlier, the Law alone is not enough to make a change in society. It is mandatory, but its implementation depends on the commitment of citizens, institutions and corporations.
				<br><br>
				It should be emphasized that a person spends one third of the day at work, which is why it is reasonable to start a change in the sphere of work and employment. The goal is to adapt the work environment not to special, but to universal human needs so that everyone can enjoy the benefits of working. In addition, with the Equality Forum, all Aluminij employees have the opportunity to transfer knowledge and supportive spirit to others, advocating change for a better future.
			</span>
	
			<img src="../assets/dragana.png" alt="Dragana Kraljević" class="speaker-img">
			<span class="w-100 font-weight-bold pink-text">Statement by Mrs. Dragana Kraljević</span>
			<span class="w-100 white--text mb-3">Head of Human Resources at Aluminij Industries</span>
			<span class="w-100 agenda-span mb-5">
				Businesses can help ensure that their existing policy and benefits infrastructure is inclusive of the LGBTQ workforce and their families.
				<br><br>
				Today's workforce is increasingly diverse in terms of personal characteristics such as:
				<br><br>
				race,
				<br>ethnicity,
				<br>gender,
				<br>national origin,
				<br>religion,
				<br>gender identity,
				<br>sexual orientation
				<br><br>The “business case for diversity” suggests that such diversity in the workplace will lead to lower costs and/or higher revenues, improving the bottom line. Not surprisingly, employers have considered the economic benefits of adding lesbian, gay, bisexual, and transgender (LGBT) supportive policies, including sexual orientation and gender-identity nondiscrimination policies and domestic partner benefits policies.
				<br><br>
				Now when a company has friendly HR policies, it has set a baseline for welcoming LGBTQ employees. Though some organizations are not discriminating actively, sometimes recruiters succumb to unconscious bias in the recruitment process. So employers should ensure they have a transparent and fair selection process, and there is no room for bias.
				<br><br>
				Getting people through the door is a half battle as there is a need to train them well to retain them and making arrangements or room for long term associations. Business leaders can help shift cultures and advance LGBT equality globally. From arranging support events like forums, education, spirit-day, extending various benefits till helping them building networks. Eventually, business leaders can help them to build an LGBT friendly work community bit by bit. Jóhanna Sigurðardóttir, a former Icelandic Prime Minister and the first openly gay head of state once said: „It is absolutely imperative that every human being’s freedom and human rights are respected, all over the world.”
			</span>

			<img src="../assets/damir.png" alt="Damir Banović" class="speaker-img">
			<span class="w-100 font-weight-bold pink-text">Statement by Mr. Damir Banović</span>
			<span class="w-100 white--text mb-3">Ph.D, Assistant professor, University of Sarajevo - Faculty of Law</span>
			<span class="w-100 agenda-span mb-5">
				The Equality Forum was a great opportunity to discuss different topics related to LGBTIQ issues in Bosnia and Herzegovina. In my opinion, it was very important to have it in Mostar, a city in Bosnia and Herzegovina which hadn’t had local elections for 12 years. But the changes have started. Last year, the citizens of Mostar elected a new municipal council and got a new major. In 2021, the Equality Forum put the LGBTIQ on agenda in the biggest city in Herzegovina. I hope, this is only one of the many steps in recognizing the importance of human rights in Herzegovina.
				<br><br>
				In modern legal systems, introducing legal protection for a specific category is only the first step. Bosnia and Herzegovina has taken these steps first by adopting the Gender Equality Law in 2003 and Antidiscrimination Law in 2009. The Antidiscrimination Law is one of the most important legal documents in the Bosnian-Herzegovinian a legal system that introduced legal measures and institutional support for combating discrimination in Bosnia and Herzegovina. Discrimination is prohibited in different areas of public and private life including, among the others, sexual orientation, gender identity, and sex characteristics as prohibited grounds for discrimination. Nevertheless, there is much to be done within the area of implementation of the law; adopting bylaws and strategies; changing everyday practices; raising awareness. The Equality Forum is one piece of the puzzle in reaching the goal of full legal and factual equality of LGBTIQ individuals.
			</span>

			<img src="../assets/ivona.png" alt="Ivona Čarapina Zovko" class="speaker-img">
			<span class="w-100 font-weight-bold pink-text">Statement by Mrs. Ivona Čarapina Zovko</span>
			<span class="w-100 white--text mb-3">Ph.D, Assistant professor, University of Sarajevo - Faculty of Humanities and Social Sciences</span>
			<span class="w-100 agenda-span mb-5">
				Participation in the first equality forum for the LGBTQI population was an excellent opportunity to raise awareness of the importance of the topic itself. In Bosnia and Herzegovina, the emphasis is often on diversity among people, which is taken as a disadvantage and not as a (competitive) advantage. In this forum, I spoke on the topic of diversity and its impact on organizational performance. The biggest mistake is to ignore diversity. Worse than that is to discriminate against someone on the basis of "diversity". In the workplace, precisely because of the lack of talk on this topic and without sufficient education of both managers and staff, the consequences can be far-reaching and even violent. I emphasized the division of private and business roles as important. At the organizational level, if we want full potential, then we must also provide the conditions for that.
				<br><br>
				According to many scientific research, the advantage of diversity in the workplace spans further than quantifiable productivity and success. Diversity at work directly affects individual employee’s satisfaction, happiness levels and organizational culture. It is the responsibility of managers to ensure a safe and inclusive work environment and to educate staff.
				<br><br>
				The forum was well organized, inspiring and an opportunity for networking. A small but initial step has been made for an environment like Herzegovina. I hope that the forums will continue to be held with as many participants as possible and that the broader message sent through these forums will accept and sensitize the prejudices and attitudes of the public.
			</span>

			<img src="../assets/marko.png" alt="Marko Mihailović" class="speaker-img">
			<span class="w-100 font-weight-bold pink-text">Statement by Mr. Marko Mihailović</span>
			<span class="w-100 white--text mb-3">Director of Pride society Serbia</span>
			<span class="w-100 agenda-span mb-md-12 mb-8">
				I was honored to be one of the speakers at the first Equality forum organized by the team of Aluminij. As the organizer of Belgrade Pride and as a human rights activist, I believe it is a crucial step towards equality to engage as many stakeholders as possible in giving support and getting them to be directly involved in building a better society devoid of discrimination and a society where human rights are respected without compromise. A starting point must be building an accepting and safe workplace. For this reason - I would like to give my strongest support to the continuation of the efforts of Aluminij to address the issues of discrimination and the promotion of diversity and acceptance.
				<br><br>
				My gratitude goes to the whole team of Aluminij, especially to the CEO Mr. Amir Gross Kabiri, for this pioneering effort and unrelentless dedication to equality; my gratitude also goes to fellow speakers as well as the staff and crew that made the Equality Forum possible. I am sure that the Equality Forum will continue to inspire people and I hope that other stakeholders will soon understand the importance of human rights, not only for building a better workplace situation, but for society as a whole.
			</span>
		</v-container>

		<v-container class="vuetify-container w-100 flex-center flex-column pb-12 mt-12" id="contact">
			<h2 class="white--text w-100 text-center font-weight-light contact-info">
				mtabraham.com
			</h2>
			<h2 class="white--text w-100 text-center font-weight-light contact-info">
				info@mtabraham.com
			</h2>
		</v-container>
	</div>
</template>

<script>
export default {
	name: "TwentyOne"
}
</script>